.stores-container {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .stores-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .stores-list {
    list-style: none;
    padding: 0;
    width: 100%;
    max-width: 600px;
  }
  
  .store-item {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .store-item:hover {
    background-color: #db3030;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .store-icon {
    font-size: 50px;
    margin-right: 15px;
    color: #333;
  }
  
  
  .store-item h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }
  
  .store-item p {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
  
  .error-message {
    color: red;
    margin-bottom: 20px;
  }
  