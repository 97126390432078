.tags {
  margin: 40px;
}

.add-tag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.add-tag input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  flex: 1;
  min-width: 100px;
}

.add-tag input[type="text"]:focus {
  border-color: black;
  outline: none;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.add-tag button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #e12f2f;
  border: none;
  border-radius: 4px;
  color: white;
  transition: background-color 0.3s;
  flex: 0 0 auto;
}

.add-tag button:hover {
  background-color: #c9302c;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
}

thead {
  background-color: #f2f2f2;
}

tbody td input[type="text"] {
  width: 100%;
  padding: 10px;
}

tbody td .fa-edit,
tbody td .fa-trash {
  cursor: pointer;
  margin-right: 20px;
  font-size: 1.5em;
  color: red;
  transition: color 0.3s;
}

tbody td .fa-edit:hover,
tbody td .fa-trash:hover {
  color: darkred;
}

tbody td button.save-button {
  padding: 5px 10px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: red;
  border: none;
  color: white;
  margin: 0 5px;
  /* Adjust as needed to ensure spacing between buttons */
  width: 115px;
  /* Set a fixed width to make them even */
  text-align: center;
  /* Center text if the width is fixed */
}

tbody td button.save-button:hover {
  background-color: darkred;
}

/* Appliquer la couleur rouge à toutes les cases à cocher */
input[type="checkbox"] {
  accent-color: red;
}

@media (max-width: 600px) {
  .add-tag {
    flex-direction: column;
    align-items: stretch;
    width: 80%;
  }

  .add-tag input,
  .add-tag label,
  .add-tag button {
    flex: 1 1 100%;
    margin-bottom: 10px;
  }

  .add-tag label {
    justify-content: flex-start;
  }
}

.errorResult,
.successResult {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}

.errorResult {
  background-color: red;
  color: white;
}

.successResult {
  background-color: rgb(28, 227, 28);
  color: white;
}

.confirm-dialog {
  background-color: rgba(200, 195, 195, 0.95);
  /* Black background */
}