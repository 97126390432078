.orders-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}


.notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #999;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
  cursor: pointer;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.notification p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}



.filter {
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.filter::-webkit-scrollbar {
  display: none;
}

.filters-menu {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
  margin: 0;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.search-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  margin-left: auto; 
}

.order-search-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 8px; 
  outline: none;
  transition: border-color 0.3s;
}

.order-search-input:focus {
  border-color: red;
}



.filters-menu {
  display: flex;
  justify-content: flex-start; 
  flex: 1;
}

.filters-menu li {
  cursor: pointer;
  padding: 10px 20px;
  position: relative;
  transition: color 0.3s ease;
}

.filters-menu li.active {
  color: rgb(225, 47, 47);
}

.filters-menu li::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.filters-menu li.active::after,
.filters-menu li:hover::after {
  background-color: rgb(225, 47, 47);
}


.order-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.order-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 18px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.order-item h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 10px;
}

.order-item .status {
  font-size: 17px;
  background-color: rgb(225, 47, 47);
  padding: 5px 10px;
  border-radius: 12px;
}

.order-item h4 {
  font-size: 16px;
  margin-top: 10px;
}

.order-item p {
  margin: 5px 0;
}

.status-arrow {
  font-size: 28px;
  cursor: pointer;
  color: rgb(225, 47, 47);
  background-color: #fff;
  border: 1px solid rgb(225, 47, 47);
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  bottom: 20px;
  right: 25px;
  transition: transform 0.3s, background-color 0.3s, color 0.3s;
}

.status-arrow:hover {
  transform: rotate(90deg);
  background-color: rgb(225, 47, 47);
  color: #fff;
}

.status-arrow:active {
  transform: scale(0.9);
  background-color: rgb(180, 37, 37);
}

.popupp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupp-content {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.popupp-content h3 {
  margin-bottom: 20px;
}

.popupp-content button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgb(225, 47, 47);
  color: #fff;
  transition: background-color 0.3s;
  display: inline-block;
}

.popupp-content button:hover {
  background-color: rgb(181, 44, 44);
}

.popupp-content .non-button {
  background-color: #ccc;
}

.popupp-content .non-button:hover {
  background-color: #999;
}



@media (max-width: 600px) {
  .filters-menu {
    gap: 10px;
  }

  .filters-menu li {
    padding: 10px 15px;
  }
}

.order-details-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
}

.menu-details-box {
  flex-grow: 1;
  flex-basis: calc(33.33% - 20px);
  min-width: 200px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
}

.menu-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.menu-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.placeholder-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #ddd;
}

.menu-info {
  flex: 1;
}

.menu-name {
  margin: 0;
  color: #000;
}

.menu-quantity {
  margin: 0;
}

.menu-ingredients {
  margin-top: 10px;
}

.menu-ingredients h4 {
  margin: 5px 0;
}

.menu-ingredients ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-ingredients li {
  margin: 2px 10px;
}

.order-details {
  margin-bottom: 20px;
}

.order-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.order-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-header h3 {
  margin: 0;
}

.order-header .status {
  margin-left: 20px;
}

.order-header p {
  margin: 0;
}

.order-details-date-time {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.client-info {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.client-info h4 {
  flex-basis: 100%;
}

.client-info p {
  margin: 0;
  flex: 1;
}
.paiement-info {
  display: flex;
  flex-wrap: wrap; 
  gap: 15px; 
}

.paiement-info p {
  margin: 0; 
}

.payment-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.payment-total h4 {
  margin-bottom: 10px;
}

.payment-total p {
  margin: 0;
}