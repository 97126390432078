.header {
    width: 100%;
    height: 100vh;
    /* background: url('header_img.png') center/cover no-repeat; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-contents {
    max-width: 400px;
    width: 100%;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.header-contents h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    padding: 10px;
}

.form-group {
    margin-bottom: 15px;
}

.input-with-icon {
    position: relative;
}

.input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2rem;
    color: #333;
}

.form-group label {
    font-size: 1rem;
    color: black;
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    font-size: 1rem;
    padding: 15px;
    border: 1px solid #eae7e7;
    border-radius: 5px;
    width: 100%;
}

.form-group input:focus {
    outline: none;
    border-color: #eae7e7;
}

.button-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.btns {
    padding: 10px 0;
    display: flex;
    justify-content: center;
}

.singleBtn {
    margin-right: 10px;
}

button {
    background-color: transparent;
    color: black;
    border-radius: 5px;
    border: 1px solid #eae7e7;
    padding: 12px 20px;
    font-size: 1.2rem;
    transition: color 0.4s, background-color 0.4s;
}

.button:hover {
    color: white;
    background-color: rgb(225, 47, 47);
}

.button:active {
    transform: translateY(2px);
}

@media (min-width: 768px) and (max-width: 1024px) {
    .header-contents {
        max-width: 500px;
    }

    .header-contents h2 {
        font-size: 2.5rem;
    }

    .form-group input {
        font-size: 1.2rem;
        padding: 20px;
    }

    .input-icon {
        font-size: 1.5rem;
    }

    button {
        font-size: 1.5rem;
        padding: 15px 25px;
    }
}