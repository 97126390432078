.react-time-picker {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    font-family: Arial, sans-serif;
    width: fit-content;
}

.react-time-picker__wrapper {
    display: flex;
    align-items: center;
}

.react-time-picker__inputGroup {
    display: flex;
    align-items: center;
    margin: 0 5px;
}

.react-time-picker__inputGroup__input {
    border: none;
    border-bottom: 1px solid #ccc;
    text-align: center;
    margin: 0 2px;
    width: 20px;
}

.react-time-picker__inputGroup__divider {
    margin: 0 2px;
}

.react-time-picker__clock-button,
.react-time-picker__clear-button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 5px;
    padding: 5px;
}

.react-time-picker__clock-button:hover,
.react-time-picker__clear-button:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
}

.react-time-picker__clock-button svg,
.react-time-picker__clear-button svg {
    width: 20px;
    height: 20px;
}