.sidebar {
    width: 18%;
    min-height: 100vh;
    border: 1.5px solid #e8e8e8;    
    border-top: 0;
    font-size: max(1.5vw, 12px); /* Increase font size for better visibility */
}

.sidebar-options {
    padding-top: 30px;
    padding-left: 15%; /* Adjusted padding to fit better */
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sidebar-option {
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid #a9a9a9;
    border-right: 0;
    padding: 8px 12px; /* Increase padding for better touch targets */
    border-radius: 25% 10%;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover and active states */
}

.sidebar-option:hover,
.sidebar-option.active {
    background-color: #f0f0f0;
}

.sidebar-option .icon {
    font-size: 18px; /* Increase icon size for better visibility */
}

@media (max-width: 1024px) and (min-width: 601px) {
    /* Adjust styles for iPad (considering its width range) */
    .sidebar {
        width: 22%;
        font-size: 2vw;
    }
    .sidebar-options {
        padding-left: 10%;
    }
    .sidebar-option {
        padding: 10px 14px;
    }
    .sidebar-option .icon {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .sidebar-option p {
        display: none;
    }
    .sidebar-option .icon {
        text-align: center; 
    }
}
