/* Réglages de la barre de navigation */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 4%;
  position: relative;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: red;
}

.profile-container {
  position: relative;
  cursor: pointer;
}

.profile {
  font-size: 24px;
}

.logout-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  /* Increased z-index to be on top of the notification */
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

.logout-item {
  padding: 10px;
  cursor: pointer;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.logout-item:hover {
  background-color: #f0f0f0;
}

/* Réglages de la modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.close-icon {
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Réglages du formulaire */
form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* Ajuste l'espacement entre les éléments du formulaire */
  line-height: 1.4;
}

.form-groupp input {
  padding: 0.75rem;
  /* Augmenter le padding pour agrandir le champ de saisie */
  border: 1px solid #ccc;
  border-radius: 6px;
  /* Augmenter le rayon pour des coins plus arrondis */
  width: 100%;
  transition: border-color 0.3s ease;
  line-height: 1.5;
  /* Ajuster la hauteur de ligne pour une meilleure lisibilité */
  font-size: 1rem;
  /* Augmenter la taille de la police pour une meilleure visibilité */
}

.form-groupp input:focus {
  border-color: black;
}

.button {
  background-color: rgb(225, 47, 47);
  color: white;
  padding: 0.75rem;
  /* Augmenter le padding pour agrandir le bouton */
  border: none;
  border-radius: 6px;
  /* Augmenter le rayon pour des coins plus arrondis */
  cursor: pointer;
  margin-top: 0.5rem;
}

.button:hover {
  background-color: darkred;
}

.modal-header h2 {
  text-align: center;
  flex-grow: 1;
}