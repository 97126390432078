.categories {
  margin: 40px;
}

.add-category {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.add-category input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  flex: 1;
  min-width: 100px;
}

.add-category input:focus {
  border-color: black;
  outline: none;
}

.add-category .file-input-container {
  position: relative;
  display: inline-block;
}

.add-category .file-input-container input[type="file"] {
  display: none;
}

.add-category .file-input-container .file-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #e12f2f;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
}

.add-category .file-input-container .file-label:hover {
  background-color: #c9302c;
}

.add-category .file-input-container .fa-upload {
  font-size: 1.5em;
}

.add-category .file-input-container .tooltip {
  visibility: hidden;
  width: 140px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -70px;
  opacity: 0;
  transition: opacity 0.3s;
}

.add-category .file-input-container .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.add-category .file-input-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.add-category button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #e12f2f;
  border: none;
  border-radius: 4px;
  color: white;
  transition: background-color 0.3s;
  flex: 0 0 auto;
}

.add-category button:hover {
  background-color: #c9302c;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.table-image {
  max-width: 50px;
  /* ajustez la largeur maximale des images ici */
  height: auto;
}

th,
td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
}

thead {
  background-color: #f2f2f2;
}

tbody td input {
  width: 100%;
  padding: 10px;
}

tbody td .fa-edit,
tbody td .fa-trash {
  cursor: pointer;
  margin-right: 20px;
  font-size: 1.5em;
  color: red;
  transition: color 0.3s;
}

tbody td .fa-edit:hover,
tbody td .fa-trash:hover {
  color: darkred;
}

tbody td button.save-button {
  padding: 5px 10px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: red;
  border: none;
  color: white;
  margin: 0 5px;
  /* Adjust as needed to ensure spacing between buttons */
  width: 115px;
  /* Set a fixed width to make them even */
  text-align: center;
  /* Center text if the width is fixed */
}

tbody td button.save-button:hover {
  background-color: darkred;
}

.category-image {
  width: 50px;
  /* Adjust the width as needed */
  height: auto;
  border-radius: 5px;
  margin: 5px;
}


.confirm-dialog {
  background-color: rgba(200, 195, 195, 0.95);
  /* Black background */
}

.loading-spinner {
  width: 24px;
  height: 24px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg);
  }
}