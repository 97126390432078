.printer-scan-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

h2 {
    color: #c0392b;
    /* Red color */
}

.add-printer-form input {
    margin: 10px 5px;
    padding: 8px;
    width: calc(33% - 20px);
    border: 1px solid #ddd;
    border-radius: 4px;
}

.error-message {
    color: #e74c3c;
    /* Red */
    font-weight: bold;
    margin-top: 10px;
}

.status-light {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 10px 0;
}

.status-light.success {
    background-color: #28a745;
    /* Green for success */
}

.status-light.error {
    background-color: #e74c3c;
    /* Red for error */
}

.no-devices {
    text-align: center;
    font-style: italic;
    color: #6c757d;
    padding: 20px;
}

.error-message {
    color: #dc3545;
    /* Red color for error */
    font-weight: bold;
    margin-top: 10px;
}

.test-button {
    position: absolute;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #e12f2f;
    border: none;
    border-radius: 4px;
    color: white;
    transition: background-color 0.3s;
    margin-top: 20px;
    right: 30px;
}

.test-button:hover {
    background-color: #c9302c;
}

.fa-edit {
    cursor: pointer;
    margin-right: 20px;
    font-size: 1.5em;
    color: red;
    transition: color 0.3s;
}

.fa-edit:hover {
    color: darkred;
}
.apk-button-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
}

.apk-button {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.apk-button svg {
    margin-right: 8px;
}

.test-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.test-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
