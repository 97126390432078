/* Main container */
.settings-container {
  width: 80%;
  margin: 0 auto;
  padding: 30px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

/* Header styling */
.settings-header {
  text-align: center;
  margin-bottom: 20px;
}

.settings-header h2 {
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

/* Store parameters styling */
.store-parameters {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.store-parameter {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: background-color 0.3s, border-color 0.3s;
  text-decoration: none;
  color: #333;
}

.store-parameter:hover {
  background-color: #ffe6e6;
  /* Light red on hover */
  border-color: #ff6666;
  /* Darker red border */
}

.store-parameter:active {
  background-color: #ffcccc;
  /* Slightly darker red on active */
  border-color: #cc3333;
}

.store-parameter p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

/* Icons styling */
.icon {
  font-size: 24px;
  color: #e60000;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 20px;
}

.store-parameter {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  justify-content: space-between;
  position: relative;
}

.store-parameter p {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
  font-size: 16px;
}

.store-parameter strong {
  margin-left: auto;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}


.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: red;
}

input:checked+.slider:before {
  transform: translateX(14px);
}

.dropdown-arrow {
  cursor: pointer;
  margin-left: 10px;
  position: relative;
  display: inline-block;
}

.dropdownOpen-menu {
  position: absolute;
  top: calc(100% + 5px);
  /* Position just below the arrow */
  right: 60px;
  /* Align to the right edge */
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: max-content;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  /* Position just below the arrow */
  right: 0;
  /* Align to the right edge */
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: max-content;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.confirm-icon {
  cursor: pointer;
  /* Show pointer cursor when hovering over the icon */
  color: green;
  /* Set the color of the icon */
  font-size: 20px;
  /* Set the font size of the icon */
  margin-left: 10px;
  /* Add some space between the TimePicker and the icon */
}

.confirm-icon:hover {
  color: darkgreen;
  /* Change the color of the icon when hovering over it */
}

.input-field {
  width: 40%;
  /* Adjust the width as needed */
  padding: 8px;
  /* Adjust the padding as needed */
  font-size: 16px;
  /* Adjust the font size as needed */
  border: 1px solid #ccc;
  /* Border color */
  border-radius: 4px;
  /* Border radius */
}

.input-field:focus {
  outline: none;
  /* Remove outline when focused */
  border-color: #007bff;
  /* Change border color when focused */
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
  /* Add box shadow when focused */
}