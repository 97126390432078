/* Dropdown.css */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-arrow {
    cursor: pointer;
    display: inline-block;
    transition: transform 0.3s ease;
}

.dropdown-arrow.open {
    transform: rotate(270deg);
    /* Rotate 270 degrees to point to the right */
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Show dropdown content when open */
.dropdown .dropdown-content {
    display: block;
}