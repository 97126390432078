.btn-container {
    display: flex;
    justify-content: center;
  }  
  
  .btn {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin: 10px; /* Ajout de marges entre les boutons */
    /* display: inline-block; 
    display: flex;
    justify-content: center; */
    
  }
  
  .btn.delete {
    background-color: rgb(225, 47, 47);
    color: white;
  }
  
  .btn.delete:hover {
    background-color: darkred;
  }
  
  .btn.cancel {
    background-color: #ccc;
    color: black;
  }
  
  .btn.cancel:hover {
    background-color: #999;
  }
  