/* Container for orders */
.orders-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
}


.filter {
    display: flex;
    overflow-x: auto;
    padding: 10px 0;
    scrollbar-width: none;
    /* Masque la barre de défilement pour Firefox */
    -ms-overflow-style: none;
    /* Masque la barre de défilement pour IE et Edge */
}

.filter::-webkit-scrollbar {
    display: none;
    /* Masque la barre de défilement pour Chrome et Safari */
}



/* Filters menu styles */
.filters-menu {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 20px;
    margin: 0;
}


.filters-menu li {
    cursor: pointer;
    padding: 10px 20px;
    position: relative;
    transition: color 0.3s ease;
}


.filters-menu li.active {
    color: rgb(225, 47, 47);
}


.filters-menu li::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}


.filters-menu li.active::after,
.filters-menu li:hover::after {
    background-color: rgb(225, 47, 47);
}







/* Arrow icon style */
.status-arrow {
    font-size: 25px;
    cursor: pointer;
    color: rgb(225, 47, 47);
    /* Set the color to red */
    background-color: #fff;
    border: 1px solid rgb(225, 47, 47);
    /* Border color red */
    border-radius: 50%;
    padding: 5px;
    position: absolute;
    bottom: 20px;
    right: 25px;
    transition: transform 0.3s, background-color 0.3s, color 0.3s;
}


.status-arrow:hover {
    transform: rotate(90deg);
    background-color: rgb(225, 47, 47);
    color: #fff;
}


.status-arrow:active {
    transform: scale(0.9);
    background-color: rgb(180, 37, 37);
}






table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: left;
}

thead {
    background-color: #f2f2f2;
}



.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: black;
}

input:checked+.slider:before {
    transform: translateX(14px);
}

.add-menu {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
}

.add-menu input[type="text"],
.add-menu input[type="number"],
.modal-content input[type="number"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex: 1;
    min-width: 150px;
    box-sizing: border-box;
}

.add-menu input[type="text"]:focus {
    border-color: black;
    outline: none;
}

.add-menu label {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;
}

.add-menu .checkbox input {
    margin: 0;
    width: 16px;
    height: 16px;
}

.add-menu button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #e12f2f;
    border: none;
    border-radius: 4px;
    color: white;
    transition: background-color 0.3s;
}

.add-menu button:hover {
    background-color: #c9302c;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content label {
    display: block;
    margin: 10px 0;
}

.modal-content input,
.modal-content textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.modal-content button {
    margin-right: 10px;
}

.modal-content button {
    flex: 1;
    padding: 12px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background-color: rgb(225, 47, 47);
    color: white;
    transition: background 0.3s ease;
}

.modal-content button:hover {
    background-color: darkred;
}

.modal-content .cancel-button {
    background-color: #ccc;
}

.modal-content .cancel-button:hover {
    background-color: #999;
}

/************* ADD menu SECTION ***************/
.add-menu {
    display: flex;
    flex-wrap: wrap;
    /* Allows wrapping of flex items */
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-menu input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    flex: 1;
    /* Allow inputs to grow and shrink */
    min-width: 100px;
    /* Minimum width to ensure inputs are usable on small screens */
}

.add-menu input:focus {
    border-color: black;
    outline: none;
}

.add-menu .checkbox-container {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    flex: 0 0 auto;
    /* Prevents checkbox from growing or shrinking */
}

.add-menu .checkbox-container input {
    margin: 0;
    width: 16px;
    height: 16px;
}

.add-menu .file-input-container {
    position: relative;
    display: inline-block;
}

.add-menu .file-input-container input[type="file"] {
    display: none;
}

.add-menu .file-input-container .file-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #e12f2f;
    border-radius: 50%;
    cursor: pointer;
    color: white;
    transition: background-color 0.3s;
}

.add-menu .file-input-container .file-label:hover {
    background-color: #c9302c;
}

.add-menu .file-input-container .fa-upload {
    font-size: 1.5em;
}

.add-menu .file-input-container .tooltip {
    visibility: hidden;
    width: 140px;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -70px;
    opacity: 0;
    transition: opacity 0.3s;
}

.add-menu .file-input-container .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.add-menu .file-input-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}


.add-menu button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #e12f2f;
    /* Reddish color */
    border: none;
    border-radius: 4px;
    color: white;
    transition: background-color 0.3s;
    flex: 0 0 auto;
    /* Prevents button from growing or shrinking */
}

.add-menu button:hover {
    background-color: #c9302c;
    /* Darker reddish color for hover effect */
}

.price-container {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    /* Allow container to grow and shrink */
    min-width: 100px;
    /* Minimum width to ensure input is usable on small screens */
}

.price-container input {
    padding-right: 10px;
    /* Adjusted padding to accommodate currency symbol */
}

.price-container .currency {
    position: absolute;
    right: 30px;
    /* Adjust the positioning as needed */
    font-size: 16px;
    color: #555;
}

.tax-dropdown-cell {
    padding: 10px;
}

.tax-dropdown {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    color: #333;
    font-size: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.tax-dropdown:hover {
    border: 1px solid #888;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}