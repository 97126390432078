.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
} 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.app-content {
  /* width: 100%;
  height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-content.authenticated {
  flex-direction: row; 
  align-items: flex-start; 
  justify-content: flex-start; 
}

hr {
  width: 100%;
  margin: 0;
}

* {
  box-sizing: border-box;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
