/* Styles généraux pour tous les appareils */
.supplement-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
}

.filter {
    display: flex;
    overflow-x: auto;
    padding: 10px 0;
    scrollbar-width: none; /* Masque la barre de défilement pour Firefox */
    -ms-overflow-style: none; /* Masque la barre de défilement pour IE et Edge */
}

.filter::-webkit-scrollbar {
    display: none; /* Masque la barre de défilement pour Chrome et Safari */
}

.filter-supplement {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: nowrap; /* Empêche le wrapping des éléments */
    gap: 20px;
    margin: 0;
    white-space: nowrap; /* Empêche le wrapping du texte dans les éléments */
}

.filter-supplement li {
    cursor: pointer;
    padding: 10px 20px;
    position: relative;
    transition: color 0.3s ease;
}

.filter-supplement li.active {
    color: rgb(225, 47, 47);
}

.filter-supplement li::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.filter-supplement li.active::after,
.filter-supplement li:hover::after {
    background-color: rgb(225, 47, 47);
}

.add-supplement {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
}

.add-supplement input[type="text"],
.add-supplement input[type="number"],
.popup-content input[type="number"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex: 1;
    min-width: 150px;
    box-sizing: border-box;
}

.add-supplement input[type="text"]:focus {
    border-color: black;
    outline: none;
}

.add-supplement label {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0;
}

.add-supplement .checkbox input {
    margin: 0;
    width: 16px;
    height: 16px;
}

.add-supplement button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #e12f2f;
    border: none;
    border-radius: 4px;
    color: white;
    transition: background-color 0.3s;
}

.add-supplement button:hover {
    background-color: #c9302c;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: left;
}

thead {
    background-color: #f2f2f2;
}

.popup-supplement {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    width: 350px;
    padding: 30px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.popup-content h3 {
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;

}

.popup-content input[type="text"] {
    padding: 10px;
    font-size: 19px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    flex: 1;
    min-width: 100px;
}

.popup-content label {
    display: flex;
    gap: 10px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.popup-content button {
    flex: 1;
    padding: 12px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background-color: rgb(225, 47, 47);
    color: white;
    transition: background 0.3s ease;
}

.popup-content button:hover {
    background-color: darkred;
}

.popup-content .cancel-button {
    background-color: #ccc;
}

.popup-content .cancel-button:hover {
    background-color: #999;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: red;
}

input:checked+.slider:before {
    transform: translateX(14px);
}


/* Pour les appareils très petits (téléphones en mode portrait) */
@media (max-width: 480px) {
    .supplement-container {
        width: 95%;
        padding: 10px;
    }

    .filter-tag {
        gap: 10px;
    }

    .add-supplement {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .add-supplement button {
        width: 100%;
        margin-top: 10px;
    }

    /* .popup-content {
        width: 90%;
        padding: 20px;
    }

    .popup-content h3 {
        font-size: 18px;
    }

    .popup-content input[type="text"] {
        font-size: 16px;
    }

    .popup-content button {
        padding: 10px;
        font-size: 14px;
    } */
}


/* Pour les appareils moyens (tablettes et petits ordinateurs portables) */
@media (max-width: 992px) {
    .supplement-container {
        width: 85%;
    }

    .filter-tag {
        gap: 15px;
    }

    .add-supplement {
        gap: 8px;
    }

    /* .popup-content {
        width: 60%;
    } */
}

/* Pour les appareils larges (grands ordinateurs portables et moniteurs) */
@media (max-width: 1200px) {
    .supplement-container {
        width: 80%;
    }

    .filter-tag {
        gap: 20px;
    }

    .add-supplement {
        gap: 10px;
    }

    /* .popup-content {
        width: 50%;
    } */
}