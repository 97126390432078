.product-menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.product-section {
    margin: 20px 0;
    margin-bottom: 40px;
}

.product-section h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    /* Add spacing below headings */
    padding-right: 20px;
}

.product-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.product-table th,
.product-table td {
    padding: 8px 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.product-table th {
    background-color: #f2f2f2;
    text-align: left;
}

.sidebar-option {
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid #a9a9a9;
    border-right: 0;
    padding: 6px 8px;
    border-radius: 25% 10%;
    cursor: pointer;
}

.sidebar-option:hover {
    background-color: #f0f0f0;
}

.sidebar-option .icon {
    font-size: 15px;
}

.sidebar-option .active {
    background-color: #f0f0f0;
}


@media (max-width:100%) {

    .sidebar-option p {
        display: none;
    }

    .sidebar-option .icon {
        text-align: center;
    }
}

.fa-plus {
    cursor: pointer;
    margin-right: 20px;
    /* Spacing between icons */
    font-size: 0.7em;
    /* Icon size */
    color: red;
    /* Default color */
    transition: color 0.3s;
    /* Color transition */
    padding: 0.3em;
    /* Padding around the icon */
    border: 1px solid red;
    /* Thin border around the icon */
    border-radius: 50%;
    /* Make the border circular */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    /* Fixed width for circle */
    height: 2em;
    /* Fixed height for circle */
}

.fa-plus:hover {
    color: darkred;
    /* Hover color */
    border-color: darkred;
    /* Change border color on hover */
}

.menu-name {
    font-size: 24px;
    font-weight: bold;
    color: #e60000;
    /* Match the color scheme */
    text-align: center;
    /* Center align the name */
    margin-bottom: 20px;
}

.menu-banner {
    background-color: #f2f2f2;
    padding: 10px;
    text-align: center;
    border-bottom: 2px solid #e60000;
    margin-bottom: 75px;
}

.constraint-form-container {
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    text-align: center;
    flex-wrap: wrap;
    /* Allows items to wrap onto the next line if necessary */
}

/* Media query for tablets and smaller screens */
@media (max-width: 768px) {
    .constraint-form-container {
        flex-direction: column;
        /* Stacks items vertically on smaller screens */
        gap: 15px;
        /* Increase gap for better spacing on smaller screens */
    }
}

/* Media query for mobile screens */
@media (max-width: 480px) {
    .constraint-form-container {
        margin: 10px;
        /* Reduce margin on very small screens */
        gap: 10px;
        /* Adjust gap for very small screens */
    }
}

.constraint-label {
    display: flex;
    align-items: center;
}

.constraint-input {
    width: 100px;
    margin-left: 5px;
    text-align: center;
}

.fa-edit {
    cursor: pointer;
}

.table-row {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

.table-cell {
    padding: 10px;
}

.amount-label {
    font-weight: bold;
    font-size: 16px;
}

.amount-value {
    color: #555;
}

.edit-icon {
    cursor: pointer;
    color: #007bff;
}